
.block-number {
  line-height: 1;
}

.custom-range:focus {
    // Pseudo-elements must be split across multiple rulesets to have an effect.
    // No box-shadow() mixin for focus accessibility.
    &::-webkit-slider-thumb { box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.33); }
    &::-moz-range-thumb     { box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.33); }
    &::-ms-thumb            { box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.33); }
}
