
.form-control {
  font-weight: 300;
  border-radius: 4px;

  option {
    font-weight: 300;
  }
}

.form-control:focus,
.custom-select:focus,
.input-group.focus {
  border-color: theme-color-level('primary', -5);
  box-shadow: 0 0 5px 0 rgba($color-primary, 0.25);
}

label:not(.col-form-label) {
  font-weight: 400;
  font-size: 13px;
  letter-spacing: .5px;
  margin-bottom: 4px;
  color: $color-text-light;

  &.require::after {
    content: '*';
    color: $color-danger;
    font-weight: 500;
    margin-left: 8px
  }
}

.col-form-label small {
  color: $color-text-secondary;
  padding-left: 4px;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-row .btn-block {
  height: 100%;
}

select:invalid {
  color: red;
}

.form-control[readonly] {
  background-color: #fff;
  /*
  background-color: $color-divider-light;

  &:focus {
    border-color: $color-divider;
    background-color: $color-divider-light;
  }
  */
}


//----------------------------------------------------/
// File upload
//----------------------------------------------------/
.file-group {
  position: relative;
  overflow: hidden;

  input[type="file"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
    width: 20px;
  }

  .file-value {
    background-color: #fff;
  }
}

.file-group-inline {
  display: inline-block;
}

.custom-file input {
  cursor: pointer;
}

.custom-file-label {
  font-size: $font-size-base;
  color: $color-text-placeholder;
  font-weight: 300;

  &::after {
    cursor: pointer;
    font-weight: 400;
  }
}


//----------------------------------------------------/
// Mailer
//----------------------------------------------------/
[data-form="mailer"] .d-on-success {
  display: none;
}





.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  min-width: 200px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
  transition: all 150ms ease;
}

.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  transition: all 150ms ease;
}
