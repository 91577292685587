
.starter-picker {

}

.starter-item {
  position: relative;
  //overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid $color-divider;
  border-radius: 4px;
  min-height: 100px;
  margin-bottom: 65px;
  cursor: pointer;
  transition: 0.3s ease-out;

  span {
    flex: 1;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    transition: 0.3s ease-out;
  }

  a {
    position: absolute;
    bottom: -40px;
    width: 100%;
    display: block;
    text-align: center;
    padding: 4px 0;
    color: $color-text-light;
    background-color: $color-divider-light;
    font-size: 13px;
    font-weight: 400;

    &:hover {
      color: $color-text-dark;
      background-color: $color-secondary;
    }
  }

  &:hover {
    border-color: $color-success;// $color-divider-dark;
    background-color: $color-bg-lighter;


    a {
      //bottom: 0;
    }

    span {
      //transform: translateY(-16px);
    }
  }

  &.active {
    border-color: $color-success;
    //background-color: lighten($color-success, 43%);

    &:after {
      content: '✓';
      position: absolute;
      top: -17px;
      right: -17px;

      width: 34px;
      height: 34px;
      line-height: 34px;
      border-radius: 10rem;
      background-color: $color-success;
      color: #fff;
      font-size: 20px;
      text-align: center;

    }
  }
}
