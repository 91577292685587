.inserter {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;

  &.sticky {
    position: sticky;
    top: 100px;
  }
}

.inserter-left {
  align-items: flex-end;
}

.inserter-item {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  color: $color-text-secondary;
  //padding: 8px 16px;
  border-radius: 4px;
  margin-bottom: 12px;
  cursor: pointer;
  transition: 0.3s ease-out;
  border: 1px solid $color-divider;
  max-width: 220px;
  width: 100%;
  opacity: 0.75;

  &:last-child {
    margin-bottom: 0;
  }

  &.show,
  &:hover {
    color: $color-text-dark;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
    opacity: 1;
  }

  .icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: $color-divider;
    font-size: 22px;
    color: rgba($color-text-disable, 0.75);
  }

  .text {
    display: inline-flex;
    align-items: center;
    flex: 1;
    height: 40px;
    padding: 0 12px;
  }

  .dropdown-menu {
    width: 100%;
  }

  .dropdown-toggle::after {
    position: absolute;
    right: 1rem;
    top: 50%;
    margin-top: -3px;
  }
}

.inserter-divider {
  border-top: 1px solid $color-divider;
  margin: 8px 0 20px;
  max-width: 220px;
  width: 100%;
}

// Premium notice
//
.user-not-subscribed .inserter-item {
  &.insert-image::after,
  &.insert-navigation::after,
  &.insert-hidden::after,
  &.insert-variable::after,
  &.insert-assign::after,
  &.insert-jump::after,
  &.insert-label::after {
    @extend .badge, .badge-pale, .badge-warning;

    content: "Premium";
    position: absolute;
    right: -15px;
    display: inline-block;
    opacity: 0;
    transition: 0.5s ease-out;
  }

  &.insert-image:hover::after,
  &.insert-navigation:hover::after,
  &.insert-hidden:hover::after,
  &.insert-variable:hover::after,
  &.insert-assign:hover::after,
  &.insert-jump:hover::after,
  &.insert-label:hover::after {
    opacity: 0.6;
  }
}
