
.messages {
  list-style: none;
  padding: 0;
  margin: 0;
}

.message {
  position: relative;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  transition: opacity 0.3s ease-out;

  &.message-right {
    margin-bottom: 16px;
  }

  &.active::before {
    content: '';
    position: absolute;
    top: 0;
    left: -1rem;
    bottom: 0;
    margin-left: -1px;
    width: 2px;
    border-radius: 4px;
    background: $color-danger;
  }

  &.message-invisible {
    opacity: 0.25;
  }

  &:hover {
    .message-drag,
    .message-tools {
      opacity: 1;
    }
  }
}




.message-drag {
  width: 10px;
  height: 22px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAG0lEQVQYV2NkYGD4z8DAwMgABXAGNgGwSgwVAFbmAgXQdISfAAAAAElFTkSuQmCC);
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.message-tools {
  display: flex;
  align-items: center;
  height: 30px;
  z-index: 9;
}

.message-left {

  .message-item {

  }

  .message-tools {
    flex-direction: row-reverse;
    margin-left: 1rem;
  }
}


.message-right {
  text-align: right;
  flex-direction: row-reverse;

  .message-tools {
    margin-right: 1rem;
  }

  .message-drag {
    margin-right: 6px;
  }
}

.message-tools {
  flex-shrink: 0;
  opacity: 0;
  //margin-top: 4px;
  //margin-left: -4px;
  //margin-right: -4px;
  transition: 0.3s ease-out;

  button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 10rem;
    border: none;
    background: #7d8899;
    margin-left: 4px;
    margin-right: 4px;
    color: #fff;
    font-size: 16px;
    transition: 0.3s ease-out;
    opacity: 0.75;

    span {
      display: inline-block;
      position: absolute;
      bottom: 110%;
      font-size: 12px;
      letter-spacing: 0.5px;
      background-color: #191919;
      line-height: 1;
      padding: 4px 8px;
      border-radius: 4px;
      transition: 0.3s ease-out;

      visibility: hidden;
      opacity: 0;
      bottom: 100%;
    }

    &:hover {
      background: #4d5766;
      color: #fff;
      opacity: 1;

      span {
        visibility: visible;
        opacity: 1;
        bottom: 110%;
        transition-delay: 0.25s;
      }
    }

    &.do-remove:hover {
      background: $color-danger;
    }

  }
}

.message-move {
  cursor: grab !important;
}

.message-item [contenteditable] {
  outline: none;
}

// Dragging message
#root ~ .message {
  z-index: 1000;
}


.block-tag.message-item {
  background-color: lighten($color-warning, 40%) !important;
  border-right: 4px solid $color-warning;
  color: $color-text !important;
  border-radius: 4px !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8;
  padding-right: 8px !important;

  strong {
    background-color: $color-warning;
    border-radius: 10rem;
    color: #fff;
    font-weight: 400;
    padding: 2px 6px;
    margin-left: 10px;
  }
}

