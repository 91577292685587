
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-container {
  flex: 1;
  padding: 0;
}

.main-content {
  flex: 1;
  padding: 1.5rem;

  &.centered-box {
    display: flex;
    align-items: center;
    justify-content: space-around;

    &::before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #000;
      opacity: 0.1;
      z-index: 0;
    }

    .card-auth {
      z-index: 9;
    }
  }
}

.sidebar {
  ~ .navbar {
    left: $sidebar-width;
  }

  ~ .main-container {
    margin-left: $sidebar-width;
  }
}

.quickview {
  ~ .navbar {
    left: $sidebar-width + $inspector-width;
  }

  ~ .main-container {
    margin-left: $sidebar-width + $inspector-width;
  }


  ~ .main-content {
    margin-left: $inspector-width + $quickview-margin;
  }

}

.navbar ~ .main-container {
  margin-top: $navbar-height;
}




.bg-img-auth {
  background: url(../../assets/img/bg/auth.jpg) no-repeat center center;
  background-size: cover;
}
