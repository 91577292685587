
// Colors

$color-primary: #0facf3;
$color-dark:    #545454;
$color-darker:  #191919;


// Sizes

$chatbox-width:    360px;
$chatbox-height:   600px;

$popup-width:      220px;


// Type

$font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";


// More

$chatbox-border-radius: 8px;


.formito-container {
	position: fixed;
	right: 0;
  bottom: 0;
  z-index: 99;

  font-family: $font-family;

	&.is-open {
		.fo-popup {
			display: none;
		}

		.fo-app,
		.fo-iframe {
			transform: scale(1,1);
		}
	}
}


.fo-app {
  position: absolute;
	right: 24px;
	bottom: 24px + 56px + 12px;
	width: $chatbox-width;
	height: $chatbox-height;
	max-width: calc(100vw - 48px);
	max-height: calc(100vh - 160px);
  border-radius: $chatbox-border-radius;
  box-shadow: 0 0 30px 0 rgba(0,0,0,0.1);
  //border: 1px solid #f3f5f6;
  background: #fff;
	transform: scale(0,0);
	transform-origin: 90% bottom;
  transition: transform 0.25s ease-out;

  &:hover {
    ~ .fo-iframe-refresh {
      opacity: 0.5;
      visibility: visible;
    }
  }
}

.fo-iframe {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
	background: #fff;
	overflow: auto;
  border-radius: $chatbox-border-radius;
}

.fo-iframe-refresh {
  position: absolute;
  bottom: 36px;
  right: 90px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 10rem;
  background-color: $color-dark;
  color: #fff;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-out;

  &:hover {
    opacity: 1;
    background-color: $color-darker;
  }
}


.fo-brand {
  position: absolute;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  padding: 4px 0;
  bottom: -34px;
  left: 20px;
  opacity: 0.75;
  text-decoration: none;
  color: $color-darker;
  transition: 0.3s ease-out;

  &:hover {
    opacity: 1;
  }
}


.fo-badge {
	position: absolute;
	right: 24px;
	bottom: 24px;
	background: $color-primary url('../../assets/img/avatar.png') no-repeat center center;
	background-size: cover;
	display: inline-block;
	width: 56px;
	height: 56px;
	border-radius: 10rem;
  cursor: pointer;
  overflow: hidden;
	box-shadow: 0 0 14px rgba(0,0,0,0.1);
	transition: 0.3s ease-out;

	&:hover {
		box-shadow: 0 0 24px rgba(0,0,0,0.15);
	}

	.fo-close {
    display: inline-block;
		width: 56px;
		height: 56px;
		border-radius: 10rem;
		background: $color-primary;
		text-align: center;
		line-height: 48px;
		color: #fff;
		font-weight: 300;
		font-size: 30px;
    cursor: pointer;
    user-select: none;
		transform: scale(0,0);
		transform-origin: center center;
		transition: transform 0.3s ease-out;


		&:hover {
		}
	}

	.is-open & .fo-close {
		transform: scale(1,1);
	}
}
