
.block-links {
  width: 100%;

  .link-item {
    display: block;
    margin-bottom: 8px;
    padding: 5px 16px;
    color: $color-text;
    font-weight: 500;
    background-color: #f1f3f7;
    text-decoration: none;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid $color-divider;
    transition: 0.3s ease-out;

    &::after {
      content: '\203A';
      float: right;
      font-size: 20px;
      margin-top: -7px;
      color: $color-primary;
      transform: translateX(-8px);
      opacity: 0;
      transition: all 0.3s ease-out;
    }

    &:hover {
      color: $color-primary;
      border-color: $color-primary;
      padding-left: 24px;

      &::after {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
}

