
.block-single_choice,
.block-multiple_choice,
.block-select {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;

  .option {
    position: relative;
    min-width: 80px;
    text-align: center;
    //white-space: nowrap;
    border: 1px solid $color-divider-dark;
    //color: $color-primary;
    border-radius: 10rem;
    padding: 2px 12px;
    margin: 0 8px 8px;
    cursor: pointer;
    transition: 0.3s ease-out;

    .jmp {
      position: absolute;
      top: 1px;
      //bottom: 100%;
      left: 100%;
      //transform: translateX(-50%);
      color: #fff;
      background-color: $color-warning;
      border-radius: 10rem;
      padding: 2px 8px;
      font-weight: 500;
      font-size: 13px;
      letter-spacing: 0.5px;
      cursor: default;
      transition: 0.3s ease-out;
      z-index: 2;

      visibility: hidden;
      opacity: 0;


      &::before {
        content: '';
        border-top: 1px solid $color-warning;
        display: block;
        position: absolute;
        top: 50%;
        right: -15px;
        width: 15px;
        z-index: 0;
      }

      &::after {
        content: '';
        position: absolute;
        right: -17px;
        top: 50%;
        margin-top: -6px;
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;

        border-left: 8px solid $color-warning;
      }
    }

    &:hover {
      border-color: $color-primary;
      color: $color-primary;

      .jmp {
        visibility: visible;
        opacity: 1;
        margin-left: 8px;
        //left: 105%;
      }
    }
  }


  &.stacked {
    flex-direction: column;
  }


}
