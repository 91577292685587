.inspector {
  position: fixed;
  top: 0;
  left: $sidebar-width;
  //right: -360px;
  //right: 0;
  bottom: 0;
  width: $inspector-width;
  background: #fff;
  border-right: 1px solid $color-divider;
  //box-shadow: 0 0 25px 0 rgba(0,0,0,0.06);
  transition: right 0.3s ease-out;
  z-index: $zindex-inspector;

  &.reveal {
    right: 0;

    ~ .inspector-backdrop {
      visibility: visible;
      opacity: 1;
    }
  }
}

.inspector-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-out;
  z-index: $zindex-inspector - 1;
}

.inspector-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $navbar-height;
  padding: 0 1rem;
  border-bottom: 1px solid $color-divider;

  h5 {
    margin-bottom: 0;
    text-transform: capitalize;
  }
}

.inspector-close {
  font-size: 22px;
  padding: 4px 12px;
  margin-right: -12px;
  cursor: pointer;
  opacity: 0.75;
  transition: 0.3s ease-out;

  &:hover {
    opacity: 1;
  }
}

.inspector-body {
  padding: 1rem;
}

.col-form-label.has-help {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    line-height: 1;
    cursor: pointer;

    &:hover {
      ~ .help-tooltip {
        visibility: visible;
        opacity: 1;
        bottom: 80%;
      }
    }
  }

  .help-tooltip {
    position: absolute;
    bottom: 80%;
    background: #191919;
    color: #fff;
    font-size: 12px;
    padding: 0 6px;
    border-radius: 4px;
    //transform: translateX(80%);
    z-index: 9;

    visibility: hidden;
    opacity: 0;
    bottom: 70%;
    transition: 0.3s ease-out;
  }
}

.help-toggle {
  position: relative;
  font-size: 18px;
  color: $color-text;
  cursor: pointer;
  line-height: 1;
  display: inline-block;
  float: right;

  span {
    position: absolute;
    bottom: 100%;
    left: 0;
    background: #191919;
    color: #fff;
    font-size: 12px;
    display: block;
    width: 100%;
  }
}

.repeater {
  list-style: none;
  padding: 0;
}

.repeater-item {
  //border-left: 3px solid $color-divider;
  //padding: 10px;
  //padding-left: 12px;
  list-style: none;
  border: 1px solid $color-divider;
  border-radius: 4px;
  margin-bottom: 1rem;
  overflow: hidden;
  transition: 0.3s ease-out;

  .form-group {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .repeater-actions {
    flex-shrink: 0;
    text-align: right;
    font-size: 20px;
    opacity: 0;
    transition: 0.3s ease-out;

    a {
      color: $color-text;
      vertical-align: text-bottom;
      cursor: pointer;
      opacity: 0.5;

      &:hover {
        color: $color-text-dark;
        opacity: 1;
      }
    }

    a + a {
      margin-left: 12px;
    }

    .item-move {
      cursor: move;
    }
  }

  &:hover {
    background-color: $color-bg-lighter;

    .repeater-actions {
      opacity: 1;
    }
  }

  &.active {
    .repeater-item-content {
      display: block;
      /*
      padding: 1rem;
      height: auto;
      max-height: 300px;
      transform: translateY(0);
      */
    }
  }
}

.repeater-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  background-color: $color-divider;
  cursor: pointer;

  .header-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 8px;
  }
}

.repeater-item-content {
  /*
  height: 0;
  max-height: 0;
  transform-origin: top center;
  transform: translateY(-100%);
  */
  padding: 1rem;
  display: none;
  background-color: #fff;
  transition: 0.5s ease-out;
}

.repeater-add {
  border: 1px solid $color-divider;
  width: 100%;
  background-color: $color-bg-light;

  &:hover {
    background-color: $color-secondary;
  }
}

// Dragging message
#root ~ .repeater-item {
  z-index: 1000;
}
