
.block-accordion {
  width: 100%;

  .link-item {
    display: block;
    margin-bottom: 8px;
    padding: 5px 16px;
    color: $color-text;
    font-weight: 500;
    background-color: #f9fafb;
    text-decoration: none;
    border-radius: 3px;
    border: 1px solid $color-divider;
    transition: 0.3s ease-out;

    &::after {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      float: right;
      border-left: 1px solid #959595;
      border-top: 1px solid #959595;
      margin-top: 10px;
      margin-bottom: 3px;
      transform: rotate(-135deg);
      transition: .2s linear;
    }

    &:hover {
      color: $color-text-dark;
      background-color: #f7f8f9;
    }
  }
}

