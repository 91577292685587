
.badge {
  padding-top: 5px;
  padding-bottom: 5px;

  &:empty {
    display: inline-block;
  }
}


//----------------------------------------------------/
// Pale
//----------------------------------------------------/
@each $color, $value in $theme-colors {
  .badge-pale.badge-#{$color} {
    @if $color != secondary and $color != light {
      color: theme-color-level($color, 2);
    }
    //background-color: theme-color-level($color, -10);
    background-color: map-get($pale-colors, $color);
    border: 1px solid map-get($theme-colors, $color);
  }
}


//----------------------------------------------------/
// Glass
//----------------------------------------------------/
@each $color, $value in $theme-colors {
  .badge-glass.badge-#{$color} {
    background-color: rgba($value, 0.55);
    color: #fff;
  }
}


//----------------------------------------------------/
// Dotted
//----------------------------------------------------/
.badge-dot {
  width: 6px;
  height: 6px;
  padding: 0;
  border-radius: 100%;
  vertical-align: middle;
}


//----------------------------------------------------/
// Ring
//----------------------------------------------------/
.badge-ring {
  position: relative;
  width: 8px;
  height: 8px;
  padding: 0;
  border-radius: 100%;
  vertical-align: middle;

  &::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #fff;
    transform: scale(1);
    transition: .3s;
  }

  &.fill::after {
    transform: scale(0);
  }
}


//----------------------------------------------------/
// Number
//----------------------------------------------------/
.badge-number {
  position: relative;
  top: -10px;
  margin-left: -0.5rem;
  font-size: 0.75rem;
  padding: 2px 4px;
  border-radius: 10rem;
  min-width: 1rem;
  min-height: 1rem;
}
