
.uploader-image {
  height: auto;

  label {
    cursor: pointer;

    &:hover {
      color: $color-primary;
    }
  }

  img {
    width: 56px;
    height: 56px;
    border-radius: 10rem;
    margin-right: 1rem;
  }
}


.quickview .uploader-image {

  .file-value {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border: 2px dashed $color-divider;
    background-color: $color-bg-lighter;
  }

  img {
    width: auto;
    height: auto;
    border-radius: 0;
    margin-right: 0;
  }
}
