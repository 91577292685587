
.paged {
  display: flex;
  background: #fff;
  border: 1px solid $color-divider-light;
  max-width: 1200px;
}

.nav-paged {
  flex-direction: column;
  width: 260px;
  background-color: #f1f4f7;

  .nav-link {
    color: $color-text-light;
    border-right: 1px solid $color-divider-light;
    font-weight: 400;

    &:hover {
      background-color: #edf1f4;
    }

    &.active {
      background-color: #fff;
      color: $color-text;
      border-right-color: transparent;
    }
  }
}

.paged-content {
  flex: 1;
  padding: 2rem;
}
