
.block-text {
  background: #f1f3f7;
  padding: 6px 8px;
  border-radius: 4px;

  p:last-of-type {
    margin-bottom: 0;
  }
}
