
.editor {
  @extend .card;

  height: 100%;
  padding: 1rem;
  border-radius: 8px;
  max-width: 600px;
  margin: 0 auto;
}

/*
.form-response {
  max-width: 640px;
  margin: 0 auto;

  .message-right .message {
    color: #fff;
    background-color: $color-primary;
    padding: 0 12px;
    border-radius: 15px;
  }
}
*/


.form-response {
  max-width: 640px;
  margin: 0 auto;

  .message-item {
    max-width: 80%;
  }

  .message-right .message-item {
    background-color: $color-primary;
    color: #fff;
    padding: 2px 1rem;
    border-radius: 15px;
    display: table;
    margin-bottom: 8px;
  }
}



.card-vars .card-header {
  background-color: $color-bg-lighter;
  font-weight: 400;
}

.key-val {
  border-bottom: 1px solid $color-divider;
  margin-bottom: 1rem;
  padding-bottom: 1rem;


  h6 {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.5px;
    color: $color-text-disable;
  }

  > div {

  }

  > ul {
    list-style: none;
    padding: 0;
    margin: 0 -6px;

    li {
      display: inline-block;
      background-color: $color-bg-light;
      padding: 0 12px;
      margin: 6px;
      border-radius: 10rem;
      font-size: 14px;
    }
  }
}


.key-val-wrapper .key-val:last-child,
.card-vars .key-val:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.MuiTable-root .key-val {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;

  > ul {
    margin: -6px;
  }
}
