
.block-input,
.block-short_text,
.block-long_text,
.block-number,
.block-date,
.block-hidden,
.block-appointment,
.block-email {

  .input-group {
    display: flex;
    border-radius: 10rem;
    border: 1px solid $color-divider;
    padding: 2px;

    input {
      border: none;
      border-radius: 10rem;
      outline: none;
      padding-left: 12px;
      height: 34px;
    }

    button {
      border: none;
      background-color: $color-divider-dark;
      color: #fff;
      fill: $color-text-lighter;
      border-radius: 10rem;
      font-size: 20px;
      display: inline-block;
      width: 44px;
      height: 44px;
      margin: -4px;
      outline: none;
      cursor: pointer;
      transition: 0.3s ease-out;
      z-index: 9;

      svg {
        width: 24px;
        padding-left: 2px;
        margin-left: 4px;
        margin-top: -4px;
      }

      &:hover {
        background-color: lighten($color-primary, 15%);
        fill: $color-primary;
        transform: scale(1.15,1.15);
      }
    }
  }

}


.block-long_text .input-group {
  border-radius: 8px;
  border-bottom-right-radius: 20px;

  textarea {
    border: none;
    outline: none;
    padding-left: 12px;
    resize: none;
    overflow: hidden;
    min-height: 60px;
  }

  button {
    align-self: flex-end;
    //margin-right: -15px;
    //margin-bottom: -15px;
  }

}


.block-appointment .input-group {

  input {
    max-width: 140px;

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 1px solid $color-divider;

      + input {
        margin-left: 0;
      }
    }
  }
}
