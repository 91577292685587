//@import-normalize;

@import url("https://cdn.jsdelivr.net/npm/fontisto@v3.0.4/css/fontisto/fontisto-brands.min.css");

@import "theme";
@import "variables";

// Vendors
//
@import "vendor/bootstrap";
@import "vendor/daterangepicker";
@import "vendor/formito-badge";
@import "vendor/draftjs-editor";
@import "vendor/color-picker";
@import "vendor/material-table";
@import "mixins";

// Layout
//
@import "layout/card";
@import "layout/grid";
@import "layout/navbar";
@import "layout/quickview";
@import "layout/scafolding";
@import "layout/sidebar";
@import "layout/paged";

// Form
//
@import "form/input";
@import "form/input-group";
@import "form/input-round";
@import "form/control";
@import "form/switch";
@import "form/upload";

@import "content/image";
@import "content/code";
@import "content/media";
@import "content/table";
@import "content/typography";

@import "uikit/accordion";
@import "uikit/alert";
@import "uikit/badge";
@import "uikit/button";
@import "uikit/dropdown";
@import "uikit/modal";
@import "uikit/nav";
@import "uikit/pagination";
@import "uikit/popover";
@import "uikit/progress";
@import "uikit/spinner";
@import "uikit/starter-picker";

// Editor
//
@import "editor/editor";
@import "editor/inserter";
@import "editor/inspector";
@import "editor/message";

@import "editor/blocks/accordion";
@import "editor/blocks/cover";
@import "editor/blocks/feedback";
@import "editor/blocks/image";
@import "editor/blocks/input";
@import "editor/blocks/jump";
@import "editor/blocks/label";
@import "editor/blocks/links";
@import "editor/blocks/navigation";
@import "editor/blocks/number";
@import "editor/blocks/select";
@import "editor/blocks/social";
@import "editor/blocks/text";
@import "editor/blocks/variable";

.print-area {
  background-color: #fff;
  padding: 30px 40px;
}
