
.block-social {

  .social-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin: 5px;
    border-radius: 10rem;
    background-color: #ccc;
    color: #fff;
    text-decoration: none;
    transition: 0.3s ease-out;

    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    font-family: "fontisto";
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;

    &:hover {
      transform: scale(1.15,1.15);
    }
  }

  @each $name, $color in $brand-colors {
    .social-#{$name} {
      background-color: $color;
    }
  }

}
