.block-variable,
.block-assign {
  background-color: #f1f3f7; // #aaaafb;
  //color: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  overflow: hidden;

  .prefix {
    padding: 4px;
    padding-left: 8px;
    padding-right: 12px;
    background-color: rgba(0, 0, 0, 0.05);
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 1.8;
  }

  .value {
    padding-left: 6px;
    padding-right: 12px;

    span {
      padding: 0 4px;
    }
  }

  var {
    position: relative;
    display: inline-block;
    font-weight: 500;
    letter-spacing: 1px;

    &::after {
      content: "";
      width: 100%;
      border-bottom: 1px dashed #fff;
    }
  }
}

.block-assign {
}
