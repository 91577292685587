
.nav-link {
  color: $color-text;
  font-weight: 400;
}

.nav-link.active,
.nav-item.show .nav-link {
  color: $color-primary;
}

.nav-tabs {

  .nav-link {
    border-top-width: 2px;

    &.active {
      background-color: #fff;
    }
  }

}

.tab-content {
  padding: 1rem;
  border: 1px solid $color-divider-light;
  border-top: 0;
  background-color: #fff;
}
