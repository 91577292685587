
.block-navigation {
  width: 100%;

  .link-item {
    display: block;
    margin-bottom: 16px;
    padding: 12px 16px;
    color: $color-text;
    font-weight: 500;
    background-image: url(../../../assets/img/bg-cover.png);
    background-position: center center;
    background-size: cover;
    background-color: #f5f7f9;
    text-decoration: none;
    border-radius: 3px;
    border: 1px solid #f3f5f7;// $color-divider;
    transition: 0.3s ease-out;

    h6 {

    }

    p {
      margin-bottom: 0;
    }

    &:hover {
      color: $color-text-dark;
      //box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      border-color: #f1f3f5;
      background-color: #f1f3f5;
    }
  }
}

