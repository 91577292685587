
.block-label {

  padding: 0;
  //padding-top: 3px;
  background: #fff;
  color: #fff;
  text-align: center;

  &::before {
    content: '';
    border-top: 1px solid $color-primary;
    display: block;
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
  }

  &::after {
    content: '';
    position: absolute;
    top: 12px;
    right: 0;
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 10rem;
    background-color: $color-primary;
  }

  span {
    position: relative;
    background-color: $color-primary;
    padding: 3px 10px;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.5px;
    border-radius: 10rem;
  }
}
