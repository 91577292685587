
.block-cover-wrapper {

  &.active::before {
    bottom: 1rem;
  }

  .message-tools {
    position: absolute;
    right: 0;
  }
}

.block-cover {
  width: calc(100% + 2rem);
  background-color: $color-primary;
  color: #fff;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin: -1rem;
  margin-bottom: 0rem;
  padding: 1rem;
  background: $color-primary url(../../../assets/img/bg-cover.png);
	background-position: center center;
	background-size: cover;
	min-height: 140px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
  justify-content: flex-end;
  transition: 0.5s ease-out;

	h6,
	p {
		color: #fff;
		position: relative;
    margin-bottom: 0;
    transition: 0.9s ease-out;
	}

	h6 {
		font-size: 1.5rem;
	}
}
