
[data-provide="daterangepicker"]::after {
  display: inline-block;
  margin-left: .75em;
  vertical-align: .16em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}

.daterangepicker {
  border-color: $color-divider;

  &::before {
    border-bottom-color: $color-divider;
  }

  &.show-ranges.ltr .drp-calendar.left {
    border-left-color: $color-divider;
  }

  .drp-buttons {
    border-top-color: $color-divider;
  }

  td.active,
  td.active:hover,
  .ranges li.active {
    background-color: $color-primary;
  }
}
