
.table {
  border: 1px solid $color-divider-light;
}

.table th {
  border-top: 0;
  font-weight: 400;
}

.table thead th {
  border-bottom: 1px solid $color-divider;
}

.table-hover tbody tr {
  transition: background-color 0.2s linear;
}

// Sizes
//
.table-sm {
  th,
  td {
    padding: .5rem;
  }
}

.table-lg {
  th,
  td {
    padding: 1rem;
  }
}


.table-pricing {
  background: #fff;

  th, td {
    text-align: center;
  }

  tr th:first-child,
  tr td:first-child {
    text-align: left;
  }

  td.text-danger {
    font-size: 18px;
    line-height: 1;
    vertical-align: middle;
  }

  td small {
    color: $color-text-secondary;
  }
}
