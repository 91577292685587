
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: $sidebar-width;
  background: #262f3c;
  border-right: 1px solid #f1f2f3;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.03);
  z-index: $zindex-sidebar;
}


.sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: column;
  height: 100%;

  li {
    position: relative;
    height: $sidebar-width;

    &:first-child {
      height: $navbar-height;
    }

  }

  a {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: #505a69;
    text-decoration: none;
    transition: 0.3s ease;

    svg {
      font-size: 24px;
    }

    &:hover {
      background-color: #2c3544;

      ~ .sidebar-tooltip {
        visibility: visible;
        opacity: 1;
      }
    }

    &.active {
      background-color: #2c3544;
      color: #fff;
    }

  }
}


.sidebar-item-header a {
  background-color: $color-info !important;
  color: #fff;

  img {
    max-height: 44px;
  }
}


.sidebar-tooltip {
  position: absolute;
  left: 90%;
  top: 50%;

  display: inline-block;
  opacity: 0;
  visibility: hidden;
  color: #fff;
  background-color: #5292e9;
  white-space: nowrap;
  border-radius: 4px;
  font-size: 13px;
  padding: 0 6px;
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.07);
  transition: 0.3s ease-out;
  transform: translateY(-50%);
}
