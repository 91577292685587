.feedback {
  list-style: none;
  padding-left: 0;
  display: flex;
  margin: 0 -6px;

  li {
    margin: 0 6px;
    cursor: pointer;

    .fa-primary {
      color: #fdfdfd;
    }

    .fa-secondary {
      color: #c9ced3;
      transition: 0.3s ease;
    }

    &.active {
      .fa-primary {
        color: #fdfdfd;
      }

      .fa-secondary {
        //color: #ffda8d;
      }

      svg {
        transform: scale(1.2);
        opacity: 1;
      }
    }
  }

  svg {
    width: 38px;
    height: 38px;
    opacity: 0.85;
    transition: 0.3s ease;
  }

  &:not(.disabled) li {
    &:hover {
      svg {
        transform: scale(1.1);
      }
    }

    &:hover,
    &.active {
      &.feedback-1 .fa-secondary {
        color: #ff1248;
      }

      &.feedback-2 .fa-secondary {
        color: #f39380;
      }

      &.feedback-3 .fa-secondary {
        color: #ffda8d;
      }

      &.feedback-4 .fa-secondary {
        color: #39c6ff;
      }

      &.feedback-5 .fa-secondary {
        color: #4ad793;
      }
    }
  }

  &.disabled li {
    cursor: default;

    &.active {
      &.feedback-1 .fa-secondary {
        color: #ff1248;
      }

      &.feedback-2 .fa-secondary {
        color: #f39380;
      }

      &.feedback-3 .fa-secondary {
        color: #ffda8d;
      }

      &.feedback-4 .fa-secondary {
        color: #39c6ff;
      }

      &.feedback-5 .fa-secondary {
        color: #4ad793;
      }
    }

    &:not(.active) .fa-secondary {
      opacity: 0.5;
    }
  }
}
